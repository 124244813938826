import { useContext, useEffect, useRef, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ReactComponent as InfoSvg } from "../../../assets/img/info.svg";
import { ReactComponent as CheckSvg } from "../../../assets/img/Check.svg";
import { ReactComponent as ArrowSvg } from "../../../assets/img/Arrow.svg";
import { BackdropLoader, ModalButtonLoader } from "../../../components/CustomLoader/BackdropLoader";
import { BackDropModal } from "../../../components/CustomModal/backdropModal";
import i18n from "../../../i18n";
import {
  doExternalCalls,
  getMandatoryFields,
  getProductList,
  getRetailerList,
  registerProductOfUser,
} from "../../../service/ProductService";
import { AppContext } from "../../../store/AppProvider";
import { SHOW_ERROR_ALERT, SHOW_SUCCESS_ALERT } from "../../../store/reducer/AlertReducer";
import { campaignLinks, currencyMask, defaultDynFormParams, languageOptions, modelNumberLinks, privacyLinks, purchaseType, refPastDate } from "../../../utils/dataConstants";
import { RegForm, checkFileType, handleRegistria, isEmptyObj, isFutureDate, removeHtmlTags, convertDate, fileSizeRestriction, withoutTime, refFutureDate } from "../../../utils/utils";
import MaskedInput from "react-text-mask";
import heic2any from "heic2any";
import { HIDE_LOADER, SHOW_LOADER } from "../../../store/reducer/BLoaderReducer";
import './ModalStyle.css'

export const MyProductModal = ({ openModal, closeModal, getRegProductList }) => {
  const { t } = useTranslation();
  const popInputRef = useRef();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    resetField,
    setError,
    formState: { errors },
    control,
  } = useForm();

  const [yourProduct, setYourProduct] = useState(null);
  const [dynProductFormData, setDynProductFormData] = useState(defaultDynFormParams)

  const [showBdLoader, setShowBdLoader] = useState(false);
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [selectedPurchaseType, setSelectedPurchaseType] = useState("");

  const [showModelOptions, setShowModelOptions] = useState(false);
  const [fetchInProgress, setFetchInProgress] = useState(true);
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [modelInputValue, setModelInputValue] = useState("");
  const [modelNumberError, setModelNumberError] = useState(false);
  const [uploadProof, setUploadProof] = useState({});
  const [submitLoader, setSubmitLoader] = useState(false);
  const [fileTypeError, setFileTypeError] = useState({});
  const { showFileTypeError, fileErrorMessage } = fileTypeError;

  const [showForm, setShowForm] = useState(RegForm.YF);

  const locale = i18n?.resolvedLanguage;
  const { country, countryCode } = languageOptions[locale];
  const { setShowAlert, stateCode: { state_code }, setShowBLoader } = useContext(AppContext);

  useEffect(() => {
    const fetchRetailers = async () => {
      try {
        setShowBdLoader(true);
        const data = await getRetailerList(selectedPurchaseType, country);
        setRetailerOptions([...data]);
      } catch (error) {
        setRetailerOptions([]);
        setShowAlert({ type: SHOW_ERROR_ALERT, message: t('alertMessages.retailerError') });
      } finally {
        setShowBdLoader(false);
      }
    };
    if (Boolean(selectedPurchaseType)) {
      fetchRetailers();
    }
  }, [country, selectedPurchaseType]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setFetchInProgress(true)
        const data = await getProductList(modelInputValue?.trim(), locale === 'es_US' ? 'en_US' : locale);
        setModelOptions([...data]);
      } catch (error) {
        setModelOptions([]);
        setShowAlert({ type: SHOW_ERROR_ALERT, message: t('alertMessages.productError') })
      } finally {
        setFetchInProgress(false)
      }
    };
    let timer;
    if (modelInputValue.length > 2) {
      timer = setTimeout(() => fetchProducts(), 250);
    }
    if (modelInputValue.trim() === "") {
      setShowModelOptions(false);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [modelInputValue]);

  const selectPurchaseDate = useWatch({
    control,
    name: 'purchaseDate'
  });

  //reset delivery date
  useEffect(() => {
    !!selectPurchaseDate && setValue('dateOfDelivery', '');
  }, [selectPurchaseDate, setValue]);


  const onFileChange = async (event) => {
    event?.preventDefault();
    const file = event.target?.files[0];
    if (fileSizeRestriction(file)) {
      popInputRef.current.value = "";
      setFileTypeError({ showFileTypeError: true, fileErrorMessage: t("alertMessages.fileSizeExceeded") });
      return;
    }
    const isHeicFile = file?.name?.split('.')[file?.name?.split('.').length - 1] === 'heic'
    if (isHeicFile) {
      uploadHeicFile(file);
    } else {
      if (!checkFileType(file)) {
        popInputRef.current.value = "";
        setFileTypeError({ showFileTypeError: true, fileErrorMessage: t("alertMessages.fileError") });
        return;
      } else {
        const payload = {
          file_name: file?.name,
          file_type: file?.type,
          file_size: file?.size,
          file: file,
        };
        setUploadProof(payload);
        setFileTypeError({})
      }
    }
  };

  const uploadHeicFile = async (heicFileData) => {
    try {
      setShowBLoader({ action: SHOW_LOADER });
      const heicFile = await convertHeicToJpeg(heicFileData)
      const payload = {
        file_name: heicFile?.name,
        file_type: heicFile?.type,
        file_size: heicFile?.size,
        file: heicFile,
      };
      setUploadProof(payload);
    } catch (error) {
      popInputRef.current.value = "";
      setShowAlert({ type: SHOW_ERROR_ALERT, message: error.message });
    } finally {
      setFileTypeError({})
      setShowBLoader({ action: HIDE_LOADER });
    }
  }

  const convertHeicToJpeg = async (file) => {
    const jpegFile = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.94,
    });
    jpegFile.lastModifiedDate = new Date();
    jpegFile.name = `${file?.name?.split('.')?.[0]}.jpg`;
    return jpegFile
  }

  const onPressModelOption = (displayText, productId) => {
    setModelInputValue(displayText);
    setSelectedProductId(productId);
    setSelectedProduct(displayText);
    setShowModelOptions(false);
    setModelOptions([]);
  };

  const onBlurCalled = (value) => {
    if (selectedProduct?.length && selectedProduct !== value) {
      setModelInputValue('')
      setSelectedProductId(null);
      setSelectedProduct('');
      setShowModelOptions(false);
    }
  }

  const handlePurchaseType = (type) => {
    setSelectedPurchaseType(type);
    setValue('retailerName', '');
    resetField('retailAssociate');
    setError("retailerName", {
      type: 'required', message: t("validation.retailerNameReq")
    })
  }

  const notValidDataForm1 = (formData) => {
    const { purchaseDate, dateOfDelivery } = formData
    let isNotValid = false
    if (isFutureDate(purchaseDate)) {
      setError('purchaseDate', { type: 'required', message: t('validation.purchaseDtFuture') })
      isNotValid = true
    }
    if (withoutTime(purchaseDate) < withoutTime(refPastDate)) {
      setError('purchaseDate', { type: 'required', message: t('validation.purchaseDtWrongYr') })
      isNotValid = true
    }
    if (new Date(purchaseDate) > new Date(dateOfDelivery)) {
      setError('dateOfDelivery', { type: 'required', message: t('validation.deliveryDtPast') })
      isNotValid = true
    }
    if (withoutTime(dateOfDelivery) > withoutTime(refFutureDate(purchaseDate))) {
      setError('dateOfDelivery', { type: 'required', message: t('validation.deliveryDtWrongYr') })
      isNotValid = true
    }
    if (!selectedProductId) {
      setModelNumberError(true);
      isNotValid = true
    }
    return isNotValid
  }

  const onRegister = async (productData, file) => {
    try {
      setSubmitLoader(true);
      const data = await registerProductOfUser(productData);
      //capturing event for Google analytics
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "post_login_registration",
        SKU: productData?.sku,
        purchaseDate: productData?.purchase_date,
        deliveryDate: productData?.delivery_date,
        pricePaid: productData?.price_paid,
        retailername: productData?.retailer_name,
        optInEmail: productData?.receive_product_communication,
      });
      //capturing email event for Google analytics
      window.dataLayer.push({
        event: "email-opt-in",
        emailOptIn: productData?.receive_product_communication,
      })
      if (data?.uploadPopData) {
        const S3Url = data?.uploadPopData?.split('&x-amz-security-token')[0];
        await axios({ method: "PUT", url: S3Url, data: file, headers: { 'Content-Type': file.type } });
      }
      if (!!data) {
        setShowAlert({
          type: SHOW_SUCCESS_ALERT,
          message: t("alertMessages.success"),
        });
        document.getElementById("close_modal").click();
        getRegProductList();
        doExternalCalls(data?.token)
        await handleRegistria(data.token, data.registrationId, productData, locale, true);
      } else {
        setSubmitLoader(false);
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: t("alertMessages.error"),
        });
      }
    } catch (error) {
      setSubmitLoader(false);
      if (error?.response?.status == '521') {
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: t("alertMessages.duplicateProduct"),
        });
      } else {
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: t("alertMessages.catch"),
        });
      }
    }
  };

  const removeProofRef = () => {
    popInputRef.current.value = "";
    setUploadProof({})
  }

  const handleModelInputChange = (value) => {
    setShowModelOptions(true);
    setModelInputValue(value);
    //changes for error
    setModelNumberError(!value)
    if (!value) {
      setSelectedProductId(null)
    }
  };

  const clearFormFields = () => {
    const form1 = document.getElementById("productRegistration");
    const form2 = document.getElementById("productRegistrationInfo");
    form1?.classList?.remove("was-validated");
    form2?.classList?.remove("was-validated");
    closeModal();
    setModelInputValue("");
    reset();
  };

  const validationRules = {
    purchaseDate: {
      required: t("validation.purchaseDtReq"),
    },
    purchaseType: {
      required: t("validation.purchaseTypeReq"),
    },
  };

  const { purchaseDate } = validationRules;
  //changes for error
  const errorStyle = {
    display: 'block',
    background: "rgb(220, 53, 69)",
    padding: "10px 15px",
    borderRadius: "6px",
    color: "#fff"
  }
  const showOptionsBlock = (modelNumberError || !modelInputValue) ? 'none' : "block";
  const showNoMatchingResult = showModelOptions && !modelOptions?.length && modelInputValue?.length > 2 && !selectedProductId;
  const mandatoryDeliveryDate = state_code === "CA"

  const yourProductInformation = () => {
    const onErrorForm1 = (errors, event) => {
      if (!selectedProductId) {
        //changes for error
        setModelNumberError(true);
      }
      var form = document.getElementById("productRegistration");
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add("was-validated");
    };

    const fetchDynFormData = async (yourProduct) => {
      try {
        setShowBLoader({ action: SHOW_LOADER })
        const dynFormData = await getMandatoryFields(yourProduct?.product_id, countryCode, yourProduct?.purchase_date, yourProduct?.delivery_date)
        if (!isEmptyObj(dynFormData)) {
          setDynProductFormData(dynFormData)
        }
      } catch (error) {
        setShowAlert({ type: SHOW_ERROR_ALERT, message: error?.message || t('alertMessages.error') });
      } finally {
        setShowBLoader({ action: HIDE_LOADER })
      }
    }

    const onYourProductSubmit = async (formData, event) => {
      event?.preventDefault()
      if (notValidDataForm1(formData)) {
        var form = document.getElementById("productRegistration");
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add("was-validated");
        return;
      }
      const yourProduct = {
        product_id: selectedProductId || "",
        sku: selectedProduct.split(" (")[0] || "",
        locale: locale,
        purchase_date: formData?.purchaseDate || null,
        delivery_date: formData?.dateOfDelivery || null,
      };
      await fetchDynFormData(yourProduct)
      setShowForm(RegForm.PF)
      setYourProduct(yourProduct)
    }

    return <form
      className="collapse multi-collapse show needs-validation"
      id="productRegistration"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onYourProductSubmit, onErrorForm1)}
    >
      <div className="mx-0 justify-content-center justify-content-md-start d-flex form-group-wrapper flex-wrap">
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
          <a
            href={modelNumberLinks[`${country}ModelNumberLink`]}
            target="_blank"
            className="info-link order-1"
          >
            <InfoSvg />&nbsp;
            {t(`productRegistration.Where's my model number?`)}
          </a>
          <div className="dropdown order-1" onBlur={({ target: { value } }) => onBlurCalled(value)}>
            <input
              type="text"
              className="form-control"
              placeholder={t('productRegistration.modelPlaceholder')}
              name="modelNumber"
              id="modelNumber"
              value={modelInputValue}
              {...register("modelNumber", {
                onChange: (e) =>
                  handleModelInputChange(e.target.value),
                required: true,
              })}
              required
            />
            {showModelOptions &&
              <div
                style={{
                  display: showOptionsBlock,
                  position: "absolute",
                  top: "100%",
                  left: "0",
                  zIndex: "1000",
                  width: "100%",
                  margin: "-1px 0 0 0",
                  fontSize: "14px",
                  color: "#333",
                  listStyle: "none",
                  backgroundColor: "#fff",
                  backgroundClip: "padding-box",
                  borderRadius: "0 0 3px 3px",
                  maxHeight: "350px",
                  overflowY: "auto",
                  boxShadow:
                    "2px 2px 4px rgba(0, 0, 0, .08), inset 0 0 0 0.6px #d2d2d2",
                }}
              >
                <div className="list-autocomplete">
                  {selectedProductId && !modelOptions?.length ? (
                    <button
                      type="button"
                      style={{ padding: ".25rem 1rem .25rem 1rem", backgroundColor: '#CCC' }}
                      className="dropdown-item"
                      key={selectedProductId}
                      onClick={() => onPressModelOption(selectedProduct, selectedProductId)}
                    >
                      {selectedProduct}
                    </button>
                  ) : (modelOptions?.map(({ display_text, product_id }) => {
                    const parsedText = removeHtmlTags(display_text);
                    return (
                      <button
                        type="button"
                        style={{ padding: ".25rem 1rem .25rem 1rem" }}
                        className="dropdown-item"
                        key={product_id}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() =>
                          onPressModelOption(parsedText, product_id)
                        }
                      >
                        {parsedText}
                      </button>
                    );
                  })
                  )}
                </div>
              </div>}
          </div>
          <label
            htmlFor="modelNumber"
            className="form-label order-1"
            style={{ color: modelNumberError && '#dc3545' }}
          >
            {t("productRegistration.Model Number")} *
          </label>
          {!fetchInProgress && showNoMatchingResult &&
            <div style={{
              display: showOptionsBlock,
              marginTop: '0.25rem',
              fontSize: '.875em',
              background: 'rgb(220, 53, 69)',
              borderRadius: 6,
              padding: 0.75
            }}>
              <i className="hasNoResults">{t('productRegistration.No matching results')}</i>
            </div>}
          <div className="invalid-feedback" style={modelNumberError ? errorStyle : null}>
            {t("validation.modelNumReq")}
          </div>
        </div>
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end" />
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            type="date"
            name="purchaseDate"
            className="form-control order-2"
            id="purchaseDate"
            required
            max={convertDate(new Date())}
            min={convertDate(refPastDate)}
            {...register("purchaseDate", purchaseDate)}
          />
          <label
            htmlFor="purchaseDate"
            className="form-label order-2"
          >
            {t("productRegistration.Purchase Date *")}
          </label>
          {errors?.purchaseDate && <div className="invalid-feedback order-1">
            {errors?.purchaseDate?.message}
          </div>}
        </div>
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            type="date"
            name="dateOfDelivery"
            className="form-control order-2"
            id="deliveryDate"
            required={mandatoryDeliveryDate}
            disabled={!selectPurchaseDate ? true : false}
            min={selectPurchaseDate && convertDate(new Date(selectPurchaseDate))}
            max={selectPurchaseDate && convertDate(refFutureDate(selectPurchaseDate))}
            {...register("dateOfDelivery", {
              required: mandatoryDeliveryDate && t('validation.deliveryDtReq')
            })}
          />
          <label
            htmlFor="deliveryDate"
            className="form-label order-2"
          >
            {mandatoryDeliveryDate ? t("productRegistration.Delivery Date *") : t("productRegistration.Delivery Date (optional)")}
          </label>
          {errors?.dateOfDelivery && <div className="invalid-feedback order-1">
            {errors?.dateOfDelivery?.message}
          </div>}
        </div>
        <div className="form-group d-flex px-0 w-100">
          <button
            type="submit"
            className="btn btn-primary mb-4 mb-md-0 ms-auto me-md-0 me-auto"
            aria-expanded="false"
            aria-controls="productRegistration"
            style={{ width: 228 }}>
            {t("productRegistration.Continue")}
          </button>
        </div>
      </div>
    </form>
  }

  const productInformation = () => {
    const { campaignid, mandate_serialnumber, mandate_pricepaid, mandate_proofofpurchase, mandate_retailername, mandate_retailerassociate, mandate_optinmarketing } = dynProductFormData
    const onErrorForm2 = (errors, event) => {
      if (!selectedProductId) {
        //changes for error
        setModelNumberError(true);
      }
      var form = document.getElementById("productRegistrationInfo");
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add("was-validated");
    };

    const notValidDataForm2 = (formData) => {
      const { retailerName } = formData
      if (!Boolean(retailerName) || !Boolean(selectedPurchaseType)) {
        return true
      }
      if (Boolean(mandate_proofofpurchase) && !Boolean(uploadProof?.file_name)) {
        return true
      }
      return false
    }

    const onSubmitModal = (formData, event) => {
      try {
        if (notValidDataForm2(formData)) {
          var form = document.getElementById("productRegistrationInfo");
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add("was-validated");
          return;
        }
        const { file_name, file_type, file_size, file } = uploadProof;
        let product = {
          product_id: selectedProductId || "",
          sku: selectedProduct.split(" (")[0],
          locale: locale,
          serial_number: formData?.serialNumber?.trim(),
          purchase_date: formData?.purchaseDate,
          delivery_date: formData?.dateOfDelivery || null,
          retailer_id: JSON.parse(formData?.retailerName)?.retailer_id || null,
          retailer_name: JSON.parse(formData?.retailerName)?.retailer_name || null,
          retail_associate: formData?.retailAssociate,
          price_paid: formData?.pricePaid || 0,
          file_name: file_name || "",
          file_type: file_type || "",
          file_size,
          purchaseType: selectedPurchaseType,
          receive_product_communication: formData?.agreement,
        };
        onRegister(product, file);
      } catch (error) {
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: t("alertMessages.error"),
        });
      }
    };

    const showRetailAssociate = selectedPurchaseType === purchaseType.inStore && (Boolean(campaignid) && campaignid !== 'DEFAULT')

    return <form
      className="collapse multi-collapse show needs-validation"
      id="productRegistrationInfo"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmitModal, onErrorForm2)}
    >
      {(Boolean(campaignid) && campaignid !== 'DEFAULT') &&
        (<div className='info-notifier flex-wrap form-group-wrapper mb-3'>
          {t('productRegistration.campaignAvl')}
          &nbsp;<a href={`//${campaignLinks[locale]}`} target="_blank">{campaignLinks[locale]}</a>&nbsp;
          {t('productRegistration.for details')}
        </div>)}
      <div className="mx-0 justify-content-center justify-content-md-start d-flex form-group-wrapper flex-wrap">
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
          <a
            id="SerialHelp"
            className="form-text order-2"
            href="https://www.sony.com.sg/HP/pages/html/sg/help/Product_Help.html"
            target="_blank"
          >
            <InfoSvg />&nbsp;
            {t(`productRegistration.Where's my serial number?`)}
          </a>
          <input
            type="text"
            name="serialNumber"
            required={Boolean(mandate_serialnumber)}
            className="form-control order-3"
            id="serialNumber"
            {...register("serialNumber", {
              required: Boolean(mandate_serialnumber) && t("validation.serialReq")
            })}
          />
          <label
            htmlFor="serialNumber"
            className="form-label order-4"
          >
            {Boolean(mandate_serialnumber) ? t("productRegistration.Serial Number *") : t("productRegistration.Serial Number (optional)")}
          </label>
          {errors?.serialNumber && <div className="invalid-feedback order-1">
            {errors?.serialNumber?.message}
          </div>}
        </div>
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end" />
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
          <Controller
            name="pricePaid"
            control={control}
            rules={{
              required: Boolean(mandate_pricepaid) && t("validation.priceReq")
            }}
            render={({ field }) =>
              <MaskedInput
                {...field}
                id="pricePaid"
                name="pricePaid"
                mask={currencyMask}
                className="form-control order-2"
                required={Boolean(mandate_pricepaid)}
              />}
          />
          <label
            htmlFor="pricePaid"
            className="form-label order-2"
          >
            {Boolean(mandate_pricepaid) ? t("productRegistration.Price Paid *") : t("productRegistration.Price Paid (optional)")}
          </label>
          {errors?.pricePaid &&
            <div className="invalid-feedback order-1">
              {errors?.pricePaid?.message}
            </div>}
        </div>
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
          <input
            type="file"
            className="form-control order-2"
            id="proofOfPurchase"
            name="proofOfPurchase"
            required={Boolean(mandate_proofofpurchase)}
            onChange={(e) => onFileChange(e)}
            onClick={() => removeProofRef()}
            ref={popInputRef}
            accept={"image/*,.heic,.heif,application/pdf"}
          />
          <label
            htmlFor="proofOfPurchase"
            className="form-label order-2"
          >
            {Boolean(mandate_proofofpurchase) ? t("productRegistration.Proof of Purchase *") : t("productRegistration.Proof of Purchase (optional)")}
          </label>
          {(showFileTypeError) &&
            <div className="error-field">
              <div className="error-field-text">
                {fileErrorMessage}
              </div>
            </div>}
          {isEmptyObj(uploadProof) &&
            <div className="invalid-feedback order-1">
              {t('validation.popReq')}
            </div>}
        </div>
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
          <div
            className="form-check order-2"
            onChange={() => handlePurchaseType(purchaseType.online)}>
            <input
              type="radio"
              className="form-check-input"
              id="purchasedOnline"
              name="radio-stacked"
              defaultChecked={selectedPurchaseType === purchaseType.online}
              required />
            <label
              className="form-check-label"
              htmlFor="purchasedOnline">
              {t("productRegistration.Purchased Online")}
            </label>
          </div>
          <div
            className="form-check mb-3 order-2"
            onChange={() => handlePurchaseType(purchaseType.inStore)}
          >
            <input
              type="radio"
              className="form-check-input"
              id="purchasedInStore"
              name="radio-stacked"
              defaultChecked={selectedPurchaseType === purchaseType.inStore}
              required
            />
            <label
              className="form-check-label"
              htmlFor="purchasedInStore"
            >
              {t("productRegistration.Purchased In-store")}
            </label>
          </div>
          <input
            type="radio"
            className="form-check-input d-none"
            id="errorMessageTrigger"
            name="radio-stacked"
            required
          />
          <div className="invalid-feedback order-1">
            {!Boolean(selectedPurchaseType) && t("validation.purchaseTypeReq")}
          </div>
          <label
            htmlFor="errorMessageTrigger"
            className="form-label order-2"
          >
            {t("productRegistration.Did you purchase this online or in-store?")}&nbsp;*
          </label>
        </div>
        <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end" />
        {Boolean(selectedPurchaseType) &&
          <div
            className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
            <select
              className="form-select order-2"
              id="retailerName"
              defaultValue={""}
              required={Boolean(mandate_retailername)}
              {...register("retailerName", {
                required: Boolean(mandate_retailername) && t("validation.retailerNameReq")
              })}
            >
              <option disabled value=''></option>
              {retailerOptions.map((retailer) => (
                <option
                  value={JSON.stringify(retailer)}
                  key={retailer?.retailer_id}
                >
                  {retailer?.display_text}
                </option>
              ))}
            </select>
            <label
              htmlFor="retailerName"
              className="form-label order-2"
            >
              {Boolean(mandate_retailername) ? t("productRegistration.Retailer Name *") : t("productRegistration.Retailer Name (optional)")}
            </label>
            {errors?.retailerName &&
              <div className="invalid-feedback order-1">
                {errors?.retailerName?.message}
              </div>}
          </div>}
        {showRetailAssociate &&
          <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
            <input
              name="retailAssociate"
              type="text"
              className="form-control order-2"
              id="retailAssociate"
              placeholder={t("productRegistration.Retail Associate Placeholder")}
              required={Boolean(mandate_retailerassociate)}
              style={{ borderColor: errors?.retailAssociate && '#dc3545' }}
              {...register("retailAssociate", {
                required: Boolean(mandate_retailerassociate) && t("validation.retailAssociateReq"),
                maxLength: {
                  value: 50,
                  message: t("validation.maxEmailLength")
                }
              })}
            />
            <label
              htmlFor="retailAssociate"
              className="form-label order-2"
              style={{ color: errors?.retailAssociate && '#dc3545' }}
            >
              {Boolean(mandate_retailerassociate) ? t("productRegistration.Retail Associate *") : t("productRegistration.Retail Associate (optional)")}
            </label>
            {errors?.retailAssociate &&
              <div className="error-field order-1">
                <div className="error-field-text">
                  {errors?.retailAssociate?.message}
                </div>
              </div>}
          </div>}

        <div className="px-0 d-flex flex-column-reverse justify-content-end">
          <div className="form-check">
            <input
              className="form-check-input"
              name="agreement"
              type="checkbox"
              id="agreement"
              required={Boolean(mandate_optinmarketing)}
              {...register("agreement", {
                required: Boolean(mandate_optinmarketing) && t("validation.agreement")
              })}
            />
            {country === 'us' &&
              <label htmlFor="agreement" className="form-check-label">
                {t("productRegistration.receiveEmailCommunication")}
                <br />
                {t("productRegistration.agreesSonyElectronics")}
                <a
                  href={privacyLinks[`${country}PrivacyPolicyLink`]}
                  target="_blank"
                >
                  &nbsp;{t("productRegistration.Privacy Policy")} &nbsp;
                </a>
                {t("productRegistration.and certify that I am a U.S. resident.")}
                <a
                  href={privacyLinks[`${country}PrivacyNoticeLink`]}
                  target="_blank"
                >
                  &nbsp;{t("productRegistration.CA Privacy Notice")}
                </a>
              </label>
            }
            {country === 'ca' && locale === 'en_CA' &&
              <label htmlFor="agreement" className="form-check-label">
                {t("productRegistration.CA ReceiveEmailCommunication")}&nbsp;
                <a
                  href={privacyLinks[`${country}PrivacyNoticeLink`]}
                  target="_blank"
                >
                  {t("productRegistration.Privacy Policy")}&nbsp;
                </a>
                {t('productRegistration.or')}&nbsp;
                <a
                  href="https://corporate.sony.ca/view/contact_info.htm"
                  target="_blank"
                >
                  {t("productRegistration.Contact Us")}&nbsp;
                </a>
                {t('productRegistration.moreDetails')}
                <br />
                {t("productRegistration.CA AgreesSonyCanada")}
                <a
                  href={privacyLinks[`${country}PrivacyNoticeLink`]}
                  target="_blank"
                >
                  &nbsp;{t("productRegistration.Privacy Policy")}&nbsp;
                </a>
                {t("productRegistration.CA Resident")}
              </label>
            }
            {country === 'ca' && locale === 'fr_CA' &&
              <label htmlFor="agreement" className="form-check-label">
                {t("productRegistration.CA_FR_emailCommunication")}&nbsp;
                {t("productRegistration.CA_FR_refer")}&nbsp;
                <a
                  href={privacyLinks[`${country}PrivacyNoticeLink`]}
                  target="_blank"
                >
                  {t("productRegistration.CA_FR_privacyPolicy")}&nbsp;
                </a>
                {t('productRegistration.CA_FR_sonyCanada')}&nbsp;
                {t('productRegistration.or')}&nbsp;
                <a
                  href="https://corporate.sony.ca/view/contact_info.htm"
                  target="_blank"
                >
                  {t("productRegistration.CA_FR_contactUs")}&nbsp;
                </a>
                {t('productRegistration.CA_FR_moreDetails')}
                <br />
                {t("productRegistration.CA_FR_agree")}&nbsp;
                <a
                  href={privacyLinks[`${country}PrivacyNoticeLink`]}
                  target="_blank"
                >
                  {t("productRegistration.CA_FR_privacyPolicy")}&nbsp;
                </a>
                {t("productRegistration.CA_FR_sonyCanada")}&nbsp;
                {t("productRegistration.CA_FR_andCertify")}
              </label>
            }
            {errors?.agreement &&
              <div className="invalid-feedback">
                {errors?.agreement?.message}
              </div>}
          </div>
        </div>
        <div className="form-group d-flex px-0 w-100">
          <div className="d-flex justify-content-between p-0 returnBtn">
            <ArrowSvg />
            <button
              type="button"
              className="return"
              aria-expanded="false"
              aria-controls="multiCollapseExample1 multiCollapseExample3 multiCollapse1 multiCollapse2"
              onClick={() => setShowForm(RegForm.YF)}
            >
              {t("productRegistration.Return")}
            </button>
          </div>
          {submitLoader
            ? <ModalButtonLoader />
            : <button
              type="submit"
              className="btn btn-primary mb-4 mb-md-0 ms-auto me-md-0 me-auto"
              aria-expanded="false"
              aria-controls="productRegistrationInfo"
              style={{ width: 228 }}>
              {t("productRegistration.Submit Registration")}
            </button>
          }
        </div>
      </div>
    </form >
  }

  return (
    <>
      {openModal && (
        <BackDropModal>
          <div
            className={`modal fade ${openModal ? "show" : ""}`}
            id="productRegistrationDemo"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="productRegistrationDemo"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body d-flex flex-column ps-7 pe-7">
                  <i
                    id="close_modal"
                    className="modal_close-button spri-icon-xmark-solid"
                    style={{ color: "rgba(53, 51, 60, 0.5)", fontSize: 28 }}
                    data-bs-dismiss="modal"
                    onClick={() => clearFormFields()}
                  ></i>
                  <h2 className="m-0 modal_title">
                    {t("productRegistration.Product Registration")}
                  </h2>
                  <div
                    id="multiCollapse1"
                    className={`collapse multi-collapse ${Boolean(showForm) ? "show" : ""}`}>
                    <div className="d-flex align-items-center step">
                      <div className="step1">
                        <span className={`circule ${showForm === RegForm.YF ? 'active' : ''}`}>
                          {showForm === RegForm.YF || !Boolean(yourProduct) ? 1 : <CheckSvg />}
                        </span>
                        <p className="m-0">
                          {t("productRegistration.Your Product")}
                        </p>
                      </div>
                      <div className="Rectangle"></div>
                      <div className="step1">
                        <span className={`circule ${showForm === RegForm.PF && 'active'}`}>2</span>
                        <p className="m-0">
                          {t("productRegistration.Product Information")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <>
                    {showForm === RegForm.YF && yourProductInformation()}
                    {showForm === RegForm.PF && productInformation()}
                  </>
                </div>
              </div>
              <BackdropLoader
                show={showBdLoader}
                size={"medium"}
                type="modal"
              />
            </div>
          </div>
        </BackDropModal>
      )}
    </>
  );
};

